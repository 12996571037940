import useGlobalState from "@hooks/useGlobalState";
import PlayLogo from "@icons/branding/Play-logo.svg";
import BarAccount from "@icons/glyphs-tab-bar-account-off.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { DashboardNavHeader } from "src/styles/dashboard/dashboard.styles";

interface PlayNavProps {
  isLoggedIn?: boolean;
}

function PlayDashboardNav({ isLoggedIn }: PlayNavProps) {
  const router = useRouter();
  const [{ isNative }] = useGlobalState();

  const handleBack = () => {
    return isNative ? router.push("/dashboard") : router.back();
  };

  return (
    <DashboardNavHeader>
      <Link href="/dashboard" passHref legacyBehavior>
        <a
          className="play-logo"
          style={{ pointerEvents: ["/", "/dashboard"].includes(router.pathname) ? "none" : "all" }}
        >
          <PlayLogo />
        </a>
      </Link>
      {isLoggedIn && (
        <a className="account-link">
          {router.pathname === "/settings" ? (
            <button onClick={() => handleBack()}>
              <div className="close-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M18.893 18.401c.323.325-.16.82-.49.49L12 12.489l-6.403 6.402c-.328.329-.814-.165-.49-.49L11.51 12 5.107 5.597c-.326-.327.164-.816.49-.49L12 11.51l6.403-6.401c.326-.327.815.162.49.49l-6.403 6.401 6.403 6.402z"
                  />
                </svg>
              </div>
            </button>
          ) : (
            <Link href={"/settings"} passHref legacyBehavior>
              <BarAccount className="account-icon" />
            </Link>
          )}
        </a>
      )}
    </DashboardNavHeader>
  );
}

export default PlayDashboardNav;
