import styled from "styled-components";
import { colors, media } from "../styleUtils";
import { pxToRem } from "./../styleUtils";

type DashNavCardProps = { bracket: boolean; portfolio?: never } | { portfolio: boolean; bracket?: never };

interface StarProps {
  favorite?: boolean;
}

interface BCGLogoProps {
  bracket: "mens" | "womens";
}

export const DashboardNavWrapper = styled.div`
  width: 100%;
`;

export const DashboardNavHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 15px 0;
  background: var(--color_lvl_5);
  border-bottom: 0.5px solid var(--color_lvl_2);

  ${media.mvp} {
    margin: 0 0 30px 0;
  }

  ${media.svp} {
    margin: 0 0 30px 0;
  }

  & .play-logo {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;

    & svg {
      height: 35px;

      ${media.svp} {
        height: 25px;
      }
    }
  }
  & .account-link {
    position: absolute;
    right: 0;
    margin-right: 30px;
    width: 28px;
    height: 28px;

    ${media.svp} {
      margin-right: 10px;
    }
  }

  & svg {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const DashboardNav = styled.div`
  display: flex;
`;

export const DashboardNavContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  gap: 26px;
  margin-bottom: 68px;
  width: 100%;
  padding: 0 8px;

  ${media.mvp} {
    flex-flow: column;
    height: 100%;
    gap: 20px;
    margin-bottom: 48px;
  }
  ${media.svp} {
    margin-bottom: 22px;
    margin-top: 40px;
  }
`;

export const DashboardNavCard = styled.div<DashNavCardProps>`
  width: 375px;
  height: 284px;
  background: ${colors.lvl_5};
  position: relative;
  pointer-events: auto;
  border: 0.5px solid ${colors.lvl_2};
  border-radius: 7px;

  ${media.mvp} {
    width: 100%;
    max-width: 327px;
    height: 263px;
  }

  ${media.svp} {
    height: ${(props) => !props.theme.isNative && "193px"};
  }

  .logo-area {
    width: 100%;
    height: 200px;
    background: var(--color_lvl_4);
    line-height: 1.14;
    font-size: ${pxToRem(14)};
    letter-spacing: 0.47px;
    display: flex;
    font-weight: normal;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 7px 7px 0 0;

    & p {
      padding: 10px 30px;
      ${media.svp} {
        display: ${(props) => !props.theme.isNative && "none"};
      }
    }

    ${media.svp} {
      height: ${(props) => !props.theme.isNative && "130px"};
    }
  }

  .copy-area {
    line-height: 1.14;
    text-align: center;
    height: 100%;
    position: relative;
    max-height: 83px;
    padding: 0px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${pxToRem(14)};
    letter-spacing: 0.47px;
    font-weight: normal;
    border-radius: 0 0 7px 7px;
    border-top: 0.5px solid ${colors.lvl_2};

    & button {
      width: 100%;
    }

    ${media.mvp} {
      line-height: 1.33;
      letter-spacing: normal;
      max-height: 63px;
      font-size: 12px;
    }
  }

  .gray-scale {
    filter: grayscale(1);
  }
`;

export const AngledLine = styled.div`
  background-image: url("/assets/images/dashboard/assets-angled-line-super-tight-lvl-2@3x.png");
  background-size: 130%;
  height: 7px;
  width: calc(100% - 2px);
  left: 1px;
  bottom: -5px;
  position: absolute;
  border-radius: 0 0 7px 7px;
`;

export const DashboardNavFooter = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  margin: 30px 0;

  .dboi {
    width: 170px;
    height: 34px;
    margin-bottom: 10px;

    ${media.svp} {
      margin-bottom: 0;
      width: 110px;
      height: 32px;
    }
  }

  .do-not-sell {
    color: ${colors.lvl_0};
    font-size: 14px;

    ${media.svp} {
      font-size: 10px;
    }

    .separator {
      margin: 0 4px;
    }
  }

  a {
    text-decoration: none;
  }
`;

export const Star = styled.button<StarProps>`
  position: absolute;
  right: 12px;
  top: 8px;
  font-size: 24px;
  font-family: sans-serif;
  transition: color 0.2s ease-in-out;
  color: ${(props) => (props.favorite ? "#fc0" : colors.lvl_3)};

  :hover {
    color: #fc0;
  }
`;

export const BCGLogo = styled.div<BCGLogoProps>`
  background: ${(props) =>
    `url("/assets/images/dashboard/${props.bracket === "mens" ? "m" : "w"}bcg-logo-stacked-bg${
      props.theme.mode === "light" ? "l" : "d"
    }.svg")`};
  background-position: center;
  background-repeat: no-repeat;
  width: 194px;
  height: 194px;

  ${media.svp} {
    background: ${(props) =>
      `url("/assets/images/dashboard/${props.bracket === "mens" ? "m" : "w"}bcg-logo-${
        props.theme.isNative ? "stacked" : "horizontal"
      }-bg${props.theme.mode === "light" ? "l" : "d"}.svg")`};
    background-position: center;
    background-repeat: no-repeat;
    width: ${(props) => (props.theme.isNative ? "194px" : "250px")};
    height: ${(props) => (props.theme.isNative ? "114px" : "60px")};
  }
`;

export const BCGLogoNew = styled.div<BCGLogoProps>`
  background: ${(props) =>
    `url("/assets/images/dashboard/${props.bracket === "mens" ? "m" : "w"}bcg-logo-stacked-bgd.svg")`};
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
`;

export const SLCLogo = styled.div`
  background: url("/assets/images/dashboard/SLC_Stacked.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 194px;
  height: 114px;
  margin-top: 23px;

  ${media.svp} {
    background: ${(props) =>
      `url("/assets/images/dashboard/SLC_${props.theme.isNative ? "Stacked" : "Horizontal"}.svg")`};
    background-position: center;
    background-repeat: no-repeat;
    width: ${(props) => (props.theme.isNative ? "194px" : "250px")};
    height: ${(props) => (props.theme.isNative ? "114px" : "60px")};
  }
`;

export const SLCLogoNew = styled.div`
  background: url("/assets/images/dashboard/TR_STKD.svg");
  background-position: center;
  background-repeat: no-repeat;
`;

export const MCTPLogo = styled.div`
  background: url("/assets/images/dashboard/CTP_MNS.svg");
  background-position: center;
  background-repeat: no-repeat;
`;
export const WCTPLogo = styled.div`
  background: url("/assets/images/dashboard/WCTP.svg");
  background-position: center;
  background-repeat: no-repeat;
`;

export const DashboardItemTitle = styled.h3`
  font-style: normal;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-bottom: ${pxToRem(12)};
  text-transform: uppercase;
`;
