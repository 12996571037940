import { media } from "@styles/styleUtils";
import Head from "next/head";
import styled from "styled-components";

const LandingLayoutContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 320px;

  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: ${({ theme }) =>
    theme.mode === "light"
      ? ' linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url("/assets/images/landing/LM-Desktop-Reg-Background-2x.png")'
      : 'url("/assets/images/landing/DM-Desktop-Reg-Background-2x.png")'};

  ${media.mvp} {
    background-image: ${({ theme }) =>
      theme.mode === "light"
        ? 'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url("/assets/images/landing/LM-Tablet-Reg-Background-2x.png")'
        : 'url("/assets/images/landing/DM-Tablet-Reg-Background-2x.png")'};
  }

  ${media.svp} {
    background-image: ${({ theme }) =>
      theme.mode === "light"
        ? 'linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url("/assets/images/landing/LM-Mobile-Reg-Background-3x.png")'
        : 'url("/assets/images/landing/DM-Mobile-Reg-Background-2x.png")'};
  }
`;

export default function LandingLayout({ children }) {
  return (
    <>
      <Head>
        <title>Play - NCAA® March Madness®</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MarchMadnessMBB" />
        <meta name="twitter:title" content="Play Landing Page" />
        <meta name="twitter:description" content="Sign in to play March Madness Live!" />
        <meta
          name="twitter:image"
          content="https://play-staging.ncaa.com/uploads/social-share/poc/f1ad099c0cfe24304d4e1416d454ab05.jpg"
        />
      </Head>
      <LandingLayoutContainer>{children}</LandingLayoutContainer>
    </>
  );
}
