import Head from "next/head";
import styled from "styled-components";

const LandingLayoutContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
`;

export default function LandingLayout({ children }) {
  return (
    <>
      <Head>
        <title>Play - NCAA® March Madness®</title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@MarchMadnessMBB" />
        <meta name="twitter:title" content="Play Landing Page" />
        <meta name="twitter:description" content="Sign in to play March Madness Live!" />
        <meta
          name="twitter:image"
          content="https://play-staging.ncaa.com/uploads/social-share/poc/f1ad099c0cfe24304d4e1416d454ab05.jpg"
        />
      </Head>
      <LandingLayoutContainer>{children}</LandingLayoutContainer>
    </>
  );
}
