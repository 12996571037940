import useGlobalState from "@hooks/useGlobalState";
import { DashboardNavFooter } from "@styles/dashboard/dashboard.styles";

export default function PlayFooter() {
  const [{ onetrust }] = useGlobalState();

  return (
    <DashboardNavFooter>
      <div className="do-not-sell">
        <a
          onClick={() => {
            if (onetrust?.consentAction !== undefined) {
              onetrust?.consentAction();
            } else {
              window?.WBD?.UserConsent?.getLinkAction()();
            }
          }}
        >
          {onetrust.consentLabel}
        </a>
        <span className="separator" role="presentation">
          •
        </span>
        <a href="https://www.ncaa.com/tos?cid=bcg-landing" target="_blank" rel="noreferrer noopener">
          Terms
        </a>
        <span className="separator" role="presentation">
          •
        </span>
        <a href="https://www.ncaa.com/privacy#adchoices" target="_blank" rel="noreferrer noopener">
          Ad Choices
        </a>
        <span className="separator" role="presentation">
          •
        </span>
        <a href="https://www.ncaa.com/privacy?cid=bcg-landing" target="_blank" rel="noreferrer noopener">
          Privacy
        </a>
        <span className="separator" role="presentation">
          •
        </span>
        <a href="https://www.ncaa.com/contact-us?cid=bcg-landing" target="_blank" rel="noreferrer noopener">
          Feedback
        </a>
      </div>
    </DashboardNavFooter>
  );
}
