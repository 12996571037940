import PlayFooter from "@components/auth-views/PlayFooter";
import PlayDashboardNav from "@components/dashboard/PlayDashboardNav";
import AuthLandingLayout from "@components/layouts/AuthLandingLayout";
import LandingLayout from "@components/layouts/LandingLayout";
import CreateCtpEntry from "@components/Modal/CreateCtpEntry";
import HowToPlayModal from "@components/Modal/HowToPlayModal";
import useBcgGame, { Games, GamesAbbrv } from "@hooks/bcg/useBcgGame";
import useAnalytics from "@hooks/useAnalytics";
import useAuth from "@hooks/useAuth";
import useBundle from "@hooks/useBundle";
import useCookie from "@hooks/useCookie";
import useGlobalState, { useAuthView } from "@hooks/useGlobalState";
import useSession from "@hooks/useSession";
import InfoIcon from "@icons/play-game-info-icon.svg";
import { Button } from "@styles/buttons.styled";
import { BCGLogoNew, MCTPLogo, SLCLogoNew, WCTPLogo } from "@styles/dashboard/dashboard.styles";
import { colors, media, pxToRem } from "@styles/styleUtils";
import { useRouter } from "next/router";
import { ReactElement, useEffect, useState } from "react";
import settings from "src/settings";
import styled from "styled-components";

interface AuthLandingProps {
  isLoggedIn?: boolean;
}

const AuthLandingContainer = styled.div`
  width: ${pxToRem(750)};
  margin: ${pxToRem(35)};
  margin-top: ${pxToRem(20)};

  ${media.mvp} {
    max-width: ${pxToRem(750)};
    width: 95vw;
  }
`;

const LandingGamesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
`;

export default function AuthLanding({ isLoggedIn }: AuthLandingProps) {
  const [{ isNative, isIframe, modal }, dispatch] = useGlobalState();
  const { pushEventData } = useAnalytics();
  const { data: game } = useBcgGame();
  const router = useRouter();
  const { query } = router;
  const { data: bundle } = useBundle();
  const { data: session } = useSession();
  const { setAuthView } = useAuthView();
  const { setCookie } = useCookie();
  const { autoCreateEntry, handleGameRedirect, isEntryNeeded } = useAuth();

  // If client side isn't refreshed after register/login, will need to check client side cookies if logged in.
  const cookies = useCookie();
  const apiToken = cookies.getCookie("apiAuthTokenCookieName");
  const staticEndpointKey = cookies.getCookie("staticEndpointKeyCookieName");
  const nativeApiAuthToken = cookies.getCookie("nativeAppAuthTokenCookieName");
  const clientSideIsLoggedIn = Boolean((apiToken && staticEndpointKey) || nativeApiAuthToken);

  // Opens CTP creation modal
  useEffect(() => {
    const entryType = modal.options.entryType;
    if (entryType?.includes("ctp") && isEntryNeeded(entryType) && clientSideIsLoggedIn) {
      dispatch((state) => {
        state.modal.openModal = "CREATE_CTP_ENTRY";
      });
    }
  }, [dispatch, modal.options.entryType, clientSideIsLoggedIn, bundle, session, isEntryNeeded]);

  // if joinToken query is in URL show SIGN IN view
  useEffect(() => {
    if (query.joinToken) {
      setAuthView("SIGN_IN");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertGameId = (gameId: Games) => {
    switch (gameId) {
      case "ncaambracketchallenge":
        return "bcg";
      case "ncaawbracketchallenge":
        return "wbcg";
      case "ncaamsalarycap":
        return "slc";
      case "ncaamconfpickem":
        return "mctp";
      case "ncaawconfpickem":
        return "wctp";
    }
  };

  // Handles entry creation for after registering
  useEffect(() => {
    if (!bundle) return;

    let entryType;
    let url;
    let entries;

    switch (query?.type) {
      case "slc":
        entryType = "slc";
        url = `${settings.BASE_PATH}/group/${query?.groupId}`;
        entries = bundle?.mslc_entries;
        break;
      case "bcg":
        entryType = "bcg";
        url = `/mens-bracket-challenge/picks/group/${query.groupId}`;
        entries = bundle?.mbcg_entries;
        break;
      case "wbcg":
        entryType = "wbcg";
        url = `/womens-bracket-challenge/picks/group/${query.groupId}`;
        entries = bundle?.wbcg_entries;
        break;
      case "mctp":
        entryType = "mctp";
        url = `/mens-conference-tournament-challenge/group/${query.groupId}`;
        entries = bundle?.mctp_entries;
        break;
      case "wctp":
        entryType = "wctp";
        url = `/womens-conference-tournament-challenge/picks/group/${query.groupId}`;
        entries = bundle?.wctp_entries;
        break;
      default:
        break;
    }

    if (query?.fromGroupInvite && entries?.length > 0 && entryType) {
      router.push(url);
    } else if (!entries?.length && entryType) {
      autoCreateEntry(entryType).then(() => {
        if (!entryType?.includes("ctp")) handleGameRedirect(entryType);
      });
    }
  }, [query, bundle, dispatch, isNative, router, autoCreateEntry, handleGameRedirect]);

  // Play button click
  const handleCTAClick = async (gameId: GamesAbbrv) => {
    dispatch((state) => {
      state.modal.options.selectedGame = gameId;
      state.modal.options.entryType = gameId;
    });

    if (!isLoggedIn && !clientSideIsLoggedIn) {
      // For social login set a cookie of what game a user is trying to access
      setCookie("selectedGame", gameId);

      pushEventData({
        eventName: "join_start",
        eventAction: "join_start",
        eventTarget: "start",
        eventType: "play",
      }).then(() => setAuthView("SIGN_UP"));
    } else if (isEntryNeeded(gameId)) {
      autoCreateEntry(gameId).then(() => {
        if (!gameId?.includes("ctp")) handleGameRedirect(gameId);
      });
    } else {
      handleGameRedirect(gameId);
    }
  };

  const landingGamesListMens: Games[] = game?.dashboard_ordering.filter((game) => game?.includes("ncaam"));
  const landingGamesListWomens: Games[] = game?.dashboard_ordering.filter((game) => game?.includes("ncaaw"));
  const mensGames = landingGamesListMens?.map((game) => convertGameId(game));
  const womensGames = landingGamesListWomens?.map((game) => convertGameId(game));
  const [selected, setSelected] = useState<"mens" | "womens">("mens");

  useEffect(() => {
    const { tab } = query;
    if (tab === "womens") {
      setSelected("womens");
    }
  }, [query]);

  useEffect(() => {
    const { nextGame } = query;
    if (nextGame) {
      handleGameRedirect(nextGame);
    }
  }, [query, handleGameRedirect]);

  return (
    <>
      <PlayDashboardNav isLoggedIn={isLoggedIn || clientSideIsLoggedIn} />
      <LandingLayout>
        <AuthLandingContainer className={`auth-landing-${selected}`}>
          {isNative || isIframe ? null : <GameSwitcher selected={selected} setSelected={setSelected} />}
          <LandingGamesContainer>
            {selected === "mens"
              ? mensGames?.map((game) => (
                  <Game handleCTAClick={handleCTAClick} key={game} gameId={game} isLoggedIn={isLoggedIn} />
                ))
              : womensGames?.map((game) => (
                  <Game handleCTAClick={handleCTAClick} key={game} gameId={game} isLoggedIn={isLoggedIn} />
                ))}
          </LandingGamesContainer>
        </AuthLandingContainer>
        <HowToPlayModal handleCTAClick={handleCTAClick} />
        <CreateCtpEntry />
        <PlayFooter />
      </LandingLayout>
    </>
  );
}

// Game Switcher Component
const GameSwitcherContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToRem(2)};
  border: 1px solid ${colors["lvl_-5"]};
  width: ${pxToRem(324)};
  margin: 0 auto;
  margin-bottom: 24px;
  border-radius: ${pxToRem(6)};
  height: ${pxToRem(30)};

  button {
    width: 49%;
    border-radius: ${pxToRem(4)};
    height: 100%;
    font-size: ${pxToRem(12)};
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    &:not(.active) {
      border: none;
      color: ${colors["lvl_-5"]};
      background-color: ${colors["lvl_5"]};

      &:hover {
        background-color: ${colors["lvl_3"]} !important;
      }
    }

    &.active {
      color: ${colors["lvl_5"]};
      background: ${colors["lvl_-5"]};

      &:hover {
        background-color: ${colors["lvl_-3"]} !important;
      }
    }
  }
`;

function GameSwitcher(props: { selected: "mens" | "womens"; setSelected: (arg0: "mens" | "womens") => void }) {
  const { selected, setSelected } = props;

  return (
    <GameSwitcherContainer>
      <Button onClick={() => setSelected("mens")} className={selected === "mens" && "active"}>
        Men&apos;s
      </Button>
      <Button onClick={() => setSelected("womens")} className={selected === "womens" && "active"}>
        Women&apos;s
      </Button>
    </GameSwitcherContainer>
  );
}

// Individual Game
const OuterGameContainer = styled.div<{ gameId: GamesAbbrv }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${pxToRem(6)};
  background: ${colors.lvl_5};
  border: ${pxToRem(0.5)} solid ${colors.lvl_2};
  border-radius: ${pxToRem(8)};
  color: ${colors.lvl_5};
  text-align: center;
  letter-spacing: ${pxToRem(1)};
  text-transform: uppercase;
  width: 50%;
  justify-content: center;
  align-items: center;
  max-width: ${pxToRem(371)};
  min-width: ${pxToRem(155)};
  box-sizing: border-box;
  min-height: ${pxToRem(262)};

  ${media.mvp} {
    width: 49%;
    margin: 0;
  }

  ${media.svp} {
    min-width: ${({ gameId }) => gameId === "wbcg" && "100%"} !important;
    min-width: ${({ gameId }) => gameId === "wctp" && "100%"} !important;
    height: ${({ gameId }) => gameId === "wbcg" && `${pxToRem(312)};`};
    height: ${({ gameId }) => gameId === "wctp" && `${pxToRem(312)};`};
  }

  .game-logo-wrapper {
    width: 65%;
    height: ${pxToRem(125)};
    height: ${({ gameId }) => (gameId === "wctp" ? pxToRem(172) : null)};
    margin: ${pxToRem(32)} auto ${pxToRem(20)} auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: ${({ gameId }) => gameId === "wbcg" && "67px"} !important;

    ${media.svp} {
      margin-bottom: 0;
      margin-bottom: ${({ gameId }) => gameId === "wbcg" && pxToRem(44)} !important;
      width: 85%;
    }

    .logo {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  &:first-child {
    min-width: 100%;
    min-width: ${({ gameId }) => gameId === "wbcg" && "49%"};
    min-width: ${({ gameId }) => gameId === "wctp" && "49%"};
    height: ${pxToRem(312)};
    margin: 0 0 ${pxToRem(25)} 0;
    margin: ${({ gameId }) => gameId === "wbcg" && 0};
    margin: ${({ gameId }) => gameId === "wctp" && 0};

    .game-logo-wrapper {
      width: ${pxToRem(223)};
      height: ${pxToRem(146)};
      margin: ${pxToRem(30)} auto ${pxToRem(34)} auto;
      margin-bottom: ${({ gameId }) => gameId === "wbcg" && "44px"} !important;
      margin-bottom: ${({ gameId }) => gameId === "wctp" && "44px"};

      ${media.svp} {
        margin-bottom: ${({ gameId }) => gameId === "wbcg" && "34px"} !important;
        margin-bottom: ${pxToRem(34)};
        height: ${pxToRem(140)};
      }
    }

    p {
      color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
      margin-bottom: ${pxToRem(14)};
    }

    ${media.svp} {
      margin-bottom: ${pxToRem(20)};
    }
  }

  .auth-landing-womens & {
    min-width: 100%;
    height: ${pxToRem(312)};
  }
`;

const InnerGameContainer = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  color: ${colors.lvl_5};
  border-radius: 4px;

  &::after {
    content: "";
    display: block;
    background-image: url("/assets/images/landing/assets-angled-line-super-tight-lvl-2@3x.png");
    background-size: 100%;
    height: 5px;
    width: 100%;
    left: 0;
    bottom: -5px;
    position: absolute;
    border-radius: 0 0 7px 7px;
    background-position: 100% 9%;
  }

  .info-icon {
    position: absolute;
    right: 14px;
    top: 14px;
    color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
    fill: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
  }

  &.bcg {
    background: #003a5d;
  }

  &.wbcg {
    background: #003a5d;
  }

  &.slc {
    background: #0075c9;
  }

  &.mctp {
    background: #72b5e4;
  }
  &.wctp {
    background: #ec7700;
  }

  p {
    margin-bottom: 10px;
    color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};

    ${media.svp} {
      min-height: 25px;
      transform: translateY(3px);
      width: 87%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
`;

const LogoWrapper = styled.div`
  margin: ${pxToRem(35)} 0 ${pxToRem(30)} 0;
`;

const NewBadge = styled.div`
  font-style: normal;
  font-weight: 500;
  position: absolute;
  left: ${pxToRem(13)};
  top: ${pxToRem(12)};
  background: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors.lvl_5)};
  line-height: 10px;
  color: ${colors["lvl_-5"]};
  font-size: ${pxToRem(10)};
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  padding: ${pxToRem(3)} ${pxToRem(5)};
`;

const Info = styled(InfoIcon)`
  cursor: pointer;
`;

export const StyledButton = styled(Button)`
  max-width: ${pxToRem(340)};
  line-height: 15px;
  width: 100%;
  margin: 0 auto;
  border: none;
  position: absolute;
  bottom: 12px;
  width: 95%;
  right: 0;
  left: 0;

  &.game-cta-btn {
    background-color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
    color: ${({ theme }) => (theme.mode === "light" ? colors["lvl_-5"] : colors.lvl_5)};

    &:hover {
      background-color: ${({ theme }) => (theme.mode === "light" ? colors.lvl_5 : colors["lvl_-5"])};
      color: ${({ theme }) => (theme.mode === "light" ? colors["lvl_-5"] : colors.lvl_5)};
    }
  }

  ${media.mvp} {
    width: 90%;
  }

  ${media.svp} {
    width: 85%;
  }
`;

function Game(props: { gameId: GamesAbbrv; isLoggedIn: boolean; handleCTAClick: (arg0: GamesAbbrv) => void }) {
  const { gameId, isLoggedIn, handleCTAClick } = props;
  const [{ isNative, isIframe }, dispatch] = useGlobalState();
  const { data: bundle } = useBundle();
  const { data: game } = useBcgGame();
  const { data: session } = useSession();
  const [loadingGame, setLoadingGame] = useState<boolean>(false);
  const { isEntryNeeded } = useAuth();
  const isBcgClosed = !game?.play_dashboard?.bcg_open && !bundle?.mbcg_entries?.length;
  const isWbcgClosed = !game?.play_dashboard?.wbcg_open && !bundle?.wbcg_entries?.length;
  const isSlcClosed = !game?.play_dashboard?.slc_open && !bundle?.mslc_entries?.length;
  const isMctpClosed = !game?.play_dashboard?.mctp_open && !session.mctp_entries.length;
  const isWctpClosed = !game?.play_dashboard?.wctp_open && !session.wctp_entries.length;

  let bcgButtonText = isBcgClosed ? "Closed" : "Play";
  let wbcgButtonText = isWbcgClosed ? "Closed" : "Play";
  let slcButtonText = isSlcClosed ? "Closed" : "Play";
  let ctpButtonText = isMctpClosed ? "Closed" : "Play";
  let wctpButtonText = isWctpClosed ? "Closed" : "Play";

  // Tournament is over
  if (game?.state === 8) {
    bcgButtonText = !isLoggedIn || (isLoggedIn && bundle?.mbcg_entries?.length) ? "Play" : "Closed";
    wbcgButtonText = !isLoggedIn || (isLoggedIn && bundle?.wbcg_entries?.length) ? "Play" : "Closed";
    slcButtonText = !isLoggedIn || (isLoggedIn && bundle?.mslc_entries?.length) ? "Play" : "Closed";
    ctpButtonText = !isLoggedIn || (isLoggedIn && session?.mctp_entries?.length) ? "Play" : "Closed";
    wctpButtonText = !isLoggedIn || (isLoggedIn && session?.wctp_entries?.length) ? "Play" : "Closed";
  }

  const shouldShowWBCG = !(isNative || isIframe);

  const isGameDisabled = () => {
    switch (gameId) {
      case "bcg":
        return game?.state === 8 ? bcgButtonText !== "Play" : isBcgClosed;
      case "wbcg":
        return game?.state === 8 ? wbcgButtonText !== "Play" : isWbcgClosed;
      case "slc":
        return game?.state === 8 ? slcButtonText !== "Play" : isSlcClosed;
      case "mctp":
        return game?.state === 8 ? ctpButtonText !== "Play" : isMctpClosed;
      case "wctp":
        return game?.state === 8 ? wctpButtonText !== "Play" : isWctpClosed;
    }
  };

  const gameDescription = () => {
    switch (gameId) {
      case "bcg":
        return "CAN YOU PICK THE PERFECT BRACKET?";
      case "wbcg":
        return "CAN YOU PICK THE PERFECT BRACKET?";
      case "slc":
        return "PICK TEAMS + MAKE TRADES EACH ROUND";
      case "mctp":
        return "PICK A CHAMPION FOR EVERY CONFERENCE";
      case "wctp":
        return "PICK A CHAMPION FOR EVERY CONFERENCE";
    }
  };

  const gameLogo = () => {
    switch (gameId) {
      case "bcg":
        return <BCGLogoNew bracket={"mens"} className="logo" />;
      case "wbcg":
        return <BCGLogoNew bracket={"womens"} className="logo" />;
      case "slc":
        return <SLCLogoNew className="logo" />;
      case "mctp":
        return <MCTPLogo className="logo" />;
      case "wctp":
        return <WCTPLogo className="logo" />;
    }
  };

  const buttonText = () => {
    switch (gameId) {
      case "bcg":
        return loadingGame ? "LOADING..." : bcgButtonText;
      case "wbcg":
        return loadingGame ? "LOADING..." : wbcgButtonText;
      case "slc":
        return loadingGame ? "LOADING..." : slcButtonText;
      case "mctp":
        return loadingGame ? "LOADING..." : ctpButtonText;
      case "wctp":
        return loadingGame ? "LOADING..." : wctpButtonText;
    }
  };

  const handleHowToPlayModal = (gameId: GamesAbbrv) => {
    dispatch((state) => {
      state.modal.openModal = "HOW_TO_PLAY";
      state.modal.options.selectedGame = gameId;
    });
  };

  return gameId === "wbcg" && !shouldShowWBCG ? null : (
    <OuterGameContainer gameId={gameId}>
      <InnerGameContainer className={gameId}>
        <Info onClick={() => handleHowToPlayModal(gameId)} className="info-icon" />
        {gameId === "mctp" && <NewBadge>New</NewBadge>}
        <LogoWrapper className="game-logo-wrapper">{gameLogo()}</LogoWrapper>
        <p>{gameDescription()}</p>
        <StyledButton
          disabled={isGameDisabled()}
          size="md"
          $secondary
          className="game-cta-btn"
          onClick={() => {
            const isCTPGAME = gameId.includes("ctp");
            if (!isCTPGAME || (isCTPGAME && !isEntryNeeded(gameId))) setLoadingGame(true);
            handleCTAClick(gameId);
          }}
        >
          {buttonText()}
        </StyledButton>
      </InnerGameContainer>
    </OuterGameContainer>
  );
}

AuthLanding.getLayout = function getLayout(page: ReactElement) {
  return <AuthLandingLayout>{page}</AuthLandingLayout>;
};
